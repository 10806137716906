<template>
	<div class=" flex">
		<!-- 	<el-aside width="207px" style="height: 100%;background-color: #eaedf1;">
			<aside-menu :list="list" @handleSelect="handleSelect"></aside-menu>

		</el-aside> -->

		<el-main style="overflow: auto;">
			<div class="content" style="background-color: #f9f9f9;">
				<router-view />
			</div>
		</el-main>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				activeIndex: "",
			};
		},
		watch: {
			"$route.name": {
				handler(val) {
					this.activeIndex = val;
				},
				immediate: true,
			},
		},
		methods: {
			handleClick(row) {
				console.log(row, "row");
			},
			handleChange(val) {
				// this.$router.push('/equipment/equipmentLine')
			},
			handleSelect(val) {
				this.activeIndex = val;
				this.$router.push({
					name: val
				});
			},
		},
	};
</script>

<style lang='scss' scoped>
	.content {
		margin: 12px;
	}

	.el-menu-item-group .el-menu-item-group__title {
		padding: 0 !important;
	}

	.outerMenu.el-menu-item-group .el-menu-item-group__title {
		padding: 0 !important;
	}
</style>
